<template>
  <v-card
    :outlined="outlined"
    :elevation="elevation"
    :loading="loading"
    v-bind="bindings"
  >
    <v-card-title>
      {{ title }}
      <template v-if="$slots.icon">
        <v-spacer></v-spacer>
        <slot name="icon"></slot>
      </template>
    </v-card-title>
    <slot></slot>
  </v-card>
</template>

<script>
import { createBindings } from "../utils/";

export default {
  name: "UICard",
  props: {
    title: {
      type: String,
      required: true,
    },
    elevation: {
      type: Number,
      default: 3,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { attrs }) {
    const bindings = createBindings(
      [props, attrs],
      ["title", "loading", "elevation", "outlined"]
    );

    return {
      bindings,
    };
  },
};
</script>
