<template>
  <UICard :title="title">
    <slot v-bind:items="items"></slot>
  </UICard>
</template>

<script>
import { computed } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useWorkcenters } from "../store/workcenters.pinia";
import UICard from "./UICard.vue";

export default {
  name: "WorkcentersCard",
  components: {
    UICard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({
        query: {},
      }),
    },
  },
  setup(props) {
    const workcentersStore = useWorkcenters();

    const workcentersParams = computed(() => {
      const query = {
        $limit: 1000,
        $sort: { title: 1 },
        ...props.query,
      };

      return {
        query,
        debounce: 500,
      };
    });
    // 3. Provide the Model class and params in the options
    const { items, isPending } = useFind({
      model: workcentersStore.Model,
      params: workcentersParams,
    });

    return {
      workcentersStore,
      isPending,
      items,
    };
  },
};
</script>
