<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <UICard :title="$tc('facility', 1) + ' ' + $t('overview')">
          <v-list>
            <template v-for="(item, index) in facilityOverviewList">
              <v-divider v-if="item.divider" :key="index"></v-divider>
              <v-list-item v-else :key="item.title">
                <v-list-item-content class="pt-0 pb-0 mb-0">
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <template v-if="!item.tooltip">
                    <v-icon
                      icon
                      :color="item.iconColor || 'primary'"
                      v-text="item.icon"
                    ></v-icon>
                  </template>
                  <v-tooltip v-else left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :color="item.iconColor || 'primary'"
                        v-text="item.icon"
                      ></v-icon>
                    </template>
                    <span v-html="item.tooltip"></span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list>
        </UICard>
      </v-col>
      <v-col cols="12" md="4" v-bind="cardBindings">
        <WorkcentersCard
          :title="$tc('workcenter', 2) + ' ' + $t('status')"
          :query="{ facility_id: facilityId }"
          v-bind="cardBindings"
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="item in workcenterCountColumns"
                :key="item.filter"
                class="pt-0"
              >
                <v-list-item two-line>
                  <v-list-item-content class="text-center">
                    <div class="text-overline mb-0">{{ item.title }}</div>
                    <v-card-text class="pa-0">
                      <p class="text-h5 text--primary mb-0">
                        {{
                          items.filter((i) => i.active == item.filter).length
                        }}
                      </p>
                    </v-card-text>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </template>
        </WorkcentersCard>
      </v-col>
      <v-col cols="12" md="4" v-bind="cardBindings">
        <UICard
          :title="$tc('facility') + ' ' + $t('actions')"
          v-bind="cardBindings"
        >
          <v-list dense>
            <v-list-item
              v-for="item in facilityActions"
              :key="item.title"
              :to="item.to || undefined"
              v-on="item.on || undefined"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="item.icon">
                <v-icon
                  :color="item.iconColor || undefined"
                  v-text="item.icon"
                ></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </UICard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, ref, watch } from "vue-demi";
import { useGet } from "feathers-pinia";
import { useStore } from "../store/app.pinia";
import { useFacilities } from "../store/facilities.pinia";
import { createBindings } from "../utils";
import WorkcentersCard from "./WorkcentersCard.vue";
import UICard from "./UICard.vue";

export default {
  name: "FacilityOverview",
  components: {
    WorkcentersCard,
    UICard,
  },
  props: {
    facilityId: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const appStore = useStore();
    const facilitiesStore = useFacilities();

    const id = ref(props.facilityId);

    const { item: facility, isPending } = useGet({
      model: facilitiesStore.Model,
      id: id,
    });

    watch(
      () => props.facilityId,
      (newId) => (id.value = newId)
    );

    const cardBindings = createBindings([{}]);

    const workcenterCountColumns = [
      { title: root.$t("active"), filter: true },
      { title: root.$t("inactive"), filter: false },
    ];

    const canEdit = computed(() => {
      return root.$can("manageChildren", facility.value);
    });

    const facilityOverviewList = computed(() => {
      const isDefaultFacility = appStore.defaultFacilityId === id.value;
      const divider = { divider: true };

      const defaultFacilityRow = {
        title: "Your Default Facility",
        icon: isDefaultFacility ? "mdi-check" : "mdi-close",
        iconColor: isDefaultFacility ? "green" : "red",
      };

      const editRow = {
        title: "Edit Permissions",
        icon: canEdit.value ? "mdi-check" : "mdi-close",
        iconColor: canEdit.value ? "green" : "red",
        tooltip: canEdit.value
          ? false
          : "Please contact the administrator if you need <br />edit permissions to this facility.",
      };

      return [divider, defaultFacilityRow, divider, editRow];
    });

    const facilityActions = computed(() => {
      const items = [
        {
          title: `Add ${root.$tc("workcenter")}`,
          icon: "mdi-plus",
          to: {
            name: "WorkcentersEdit",
            query: { facilityId: id.value },
          },
          display: canEdit.value,
        },
      ].filter((item) => item.display);

      if (items.length === 0) {
        return [
          {
            title: "No actions available",
          },
        ];
      }

      return items;
    });

    return {
      facilitiesStore,
      facility,
      cardBindings,
      isPending,
      workcenterCountColumns,
      facilityOverviewList,
      facilityActions,
      canEdit,
    };
  },
};
</script>
